import React from 'react'
import smoothscroll from 'smoothscroll-polyfill'

import './src/assets/css/main.css'
import Layout from './src/components/layout'

export const wrapPageElement = ({ element, props }) => {
    //
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    return <Layout {...props}>{element}</Layout>
}

//
// include smoothscroll polyfill
export const onClientEntry = async () => {
    smoothscroll.polyfill()
}
