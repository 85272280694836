import React from 'react'
import Helmet from 'react-helmet'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'

export default function Layout({ children }) {
    const location = useLocation()
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    url
                    description
                }
            }
        }
    `)

    const { title, url, description } = site.siteMetadata
    const keywordsList = [
        `software`,
        `software services`,
        `interaction`,
        `design`,
        `frontend`,
        `interaction design`,
        `ui`,
        `uix`,
        `programming`,
        `system design`,
        `web applications`,
        `web`,
        `website`,
        `tripping cats`,
        `startup`,
        `branding`,
        `coding software`,
        `software engineers`,
        `application development`,
    ].join(', ')

    return (
        <>
            <Helmet>
                <html lang='es' />
                <title>{title}</title>
                <meta name='description' content={description} />
                <meta name='keywords' content={keywordsList} />

                <meta property='og:title' content={title} />
                <meta property='og:type' content='website' />
                <meta property='og:locale' content='en_US' />
                <meta property='og:image' content='../../assets/images/cats-t.png' />
                <meta property='og:description' content={description} />
                <meta property='og:url' content={location.href} />

                <link rel='icon' href='../../assets/images/cats-t.png' type='image/png' />
                <link rel='canonical' href={url + location.pathname} />
            </Helmet>
            <main>{children}</main>
        </>
    )
}
